import { useForm } from 'react-hook-form';
import { ComponentProps } from 'lib/component-props';
import { GetStaticComponentProps, constants } from '@sitecore-jss/sitecore-jss-nextjs';
import { getAllStates } from '../../../GraphQL/queries/stateList.query';
import { getAllTimeZones } from '../../../GraphQL/queries/timeZoneList.query';
import { fetchConditionOfInterest } from '../../../GraphQL/queries/fetchConditionOfInterest.query';
import ContactInfoContextProvider from '../ContactInfoContextProvider';
import { ContactInfoFieldsInterface } from '../ContactInfo.types';
import { getDefaultTimeZone } from '../../../lib/utils/timeZoneHelpers';
import { useStoreContactInfoData } from '../useStoreContactInfoData';
import { formatAdultFormData } from '../../../transformers/contactInfo.transform';
import { updateContactInfo } from '../../../features/contactInfoSlice';
import {
  constructVirtualSiteData,
  prePopulateSceeenerDataForAdultForm,
  updateConsents,
  validateSMSandIsMobile,
  prepopulateMyProfileForAdultForm,
  updateGender,
  constructConditionsOfInterest,
  callDapApiSendEmailAllDetailsAdult,
} from '../helper';
import useContactInfoHelper from '../../../lib/hooks/useContactInfoHelper';
import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import debounce from '@mui/utils/debounce';
import { ExtenedLayoutServiceContextInterface } from '../../../lib/type/Site';
import * as cdpService from '../../../services/CdpService';
import { CDP_EVENTS, JOURNEYS, SITE_SOURCE } from '../../../constants';
import { fetchNextPossiblePathAndData } from '../../../services/CdpService';
import { storeCDPData } from '../../../features/cdpDataSlice';
import {
  fetchProfileDetailsForCR,
  prePopulateEprContactDetails,
  savePatientDelegateDetailsToCDP,
} from '../../../lib/utils/axiosCDPFetcher';
import {
  ENROLLMENT_EXPIRY_TIME_FALLBACK,
  getCookie,
  getCookieExpiryTime,
  getOrCreateEnrollmentId,
  getEnrollmentId,
} from '../../../lib/utils/enrollmentUtil';
import { JOURNEY_STEP } from '../../../constants';
import { useSitecoreContext } from '@sitecore-jss/sitecore-jss-nextjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import getBaseURL from '../../../lib/utils/getBaseURL';
import { LifeLinkRequest } from '../../../types/LifeLink';
import { updateLLDetailsTODB, postToLifeLink, post } from '../../../lib/utils/axiosFetcher';
import getQueryParamsAsString from '../../../lib/utils/getQueryParamsAsString';
import { getQuery, getRespectiveCOIList, removeTrailingSlash } from '../../../lib/utils/common';
import { setCookie } from 'cookies-next';
import { checkReturningUser } from '../../../services/ReturningUserService';
import { addpotentialParticipantId } from '../../../features/potentialParticipantDetailsSlice';
import { PatientType } from '../../../lib/type/userJourney.types';
import { useMutation } from 'react-query';
import { trackEvent } from '../../../lib/utils/gtmService';
import isEmpty from 'lodash/isEmpty';
import { setSelectedCOIList } from '../../../features/registrationSlice';
import { setAuthentication } from '../../../features/authSlice';
import { setProfileData } from '../../../features/crProfileSlice';
import {
  getCommunities,
  getStringValFromArray,
  getStringValFromArrayForCDP,
} from '../../../services/RegisterUserService';
import dynamic from 'next/dynamic';
import axios from 'axios';
import { DataOrigin, updateUCPMJobRecordsForCR } from '../../../lib/utils/ucpmHelper';

export type ContactListProps = ComponentProps & ContactInfoFieldsInterface;

const ContactInfo = dynamic(() => import('../ContactInfo'), {
  ssr: false,
});

const MAX_RETRY_COUNT = 3;
const MAX_EXPERIENCE_RETRY_COUNT = 4;

const AdultContactInfo = (props: ContactListProps): JSX.Element => {
  const {
    dispatch,
    executeRecaptcha,
    selectedLanguage,
    potentialParticipant_ID,
    potentialId,
    isRecapchaEnabled,
    timeZoneList,
    screenerOutput,
    prepopulateConfig,
    reCaptchItem,
    autoCompleteLocationInfo,
    geolocation,
    cdpState,
    siteSettingsId,
    setCustomErrorModal,
    showCustomErrorModal,
    setPatientExistModal,
    showPatientExistErrorModal,
    returningUserTimeInterval,
    returningUserTimeValue,
    setEmailSentModal,
    showEmailSentModal,
    ContactNumberField,
    ConsentsMedium,
    getInitialVlaueForIsMobile,
  } = useContactInfoHelper(props);
  const router = useRouter();
  const { query } = router;

  const { sitecoreContext } = useSitecoreContext();
  const siteSettings: any = sitecoreContext?.siteSettings;
  const tenantKey = siteSettings?.tenantKey?.value || '';
  const isEnableGoogleRecaptcha = siteSettings?.EnableGoogleRecaptcha?.value || false;
  const journeyType = useSelector((state: RootState) => state?.cdpData?.journeyType);
  const cdpData = useSelector((state: RootState) => state?.cdpData);
  const selectedPlace = useSelector((state: RootState) => state.selectedSite);
  const sitecoreFieldsForContactInfo = useSelector(
    (state: RootState) => state.contactInfoDetails.sitecoreFieldsForContactInfo
  );
  const selectedCOIList = useSelector(
    (state: RootState) => state.registrationDetails.selectedCOIList
  );
  const formatedCOIList = useSelector(
    (state: RootState) => state.contactInfoDetails.conditionOfIntrestList
  );
  const isAuthenticated: boolean = useSelector(
    (state: RootState) => state.authentication.isAuthenticated
  );
  const stepNumber: number = useSelector((state: RootState) => state.contactInfoDetails.stepNumber);
  const consentPrepopulatedforTAEPR = useRef<any>(false);
  const updatePpidRef = useRef<any>(false);
  const [myProfileData, setMyProfileData] = useState<any>();
  const retuningUser = useRef<any | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const retryCount = useRef(0);
  const isCurrentResume = useRef<any>(false);

  const getFormDataDefaultValue = (existingValues: any) => {
    if (myProfileData) {
      return {
        UserTimeZone: getDefaultTimeZone(timeZoneList, existingValues),
        ...prePopulateSceeenerDataForAdultForm(
          screenerOutput,
          prepopulateConfig,
          existingValues,
          geolocation,
          sitecoreFieldsForContactInfo,
          cdpData
        ),
        ...prepopulateMyProfileForAdultForm(myProfileData, existingValues),
      };
    } else {
      return {
        UserTimeZone: getDefaultTimeZone(timeZoneList, existingValues),
        isMobile: getInitialVlaueForIsMobile(existingValues),
        ...prePopulateSceeenerDataForAdultForm(
          screenerOutput,
          prepopulateConfig,
          existingValues,
          geolocation,
          sitecoreFieldsForContactInfo,
          cdpData
        ),
      };
    }
  };

  const updateCurrentJourneyStepinCDP = async () => {
    const payload = {
      enrollmentId: getOrCreateEnrollmentId(),
      journeyStep: JOURNEY_STEP.CONTACT_INFO,
      journeyStatus: cdpState?.journeyStatus,
      isActiveEnrollment: '1',
    };

    if (potentialParticipant_ID) {
      const guestType = cdpState?.guestType || 'visitor';
      cdpService.updateCurrentJourneyInCDP(potentialParticipant_ID, guestType, payload);
    }
  };

  const prepopulateMyProfile = async () => {
    const authenticatedUserToken = sessionStorage.getItem('myProfileVerificationToken');
    if (authenticatedUserToken && isAuthenticated) {
      const response = await prePopulateEprContactDetails(authenticatedUserToken, tenantKey);
      if (response.data) {
        setMyProfileData(response.data);
      }
    }
  };

  const formHandlerObject = useForm({
    mode: 'all',
    defaultValues: async () => getFormDataDefaultValue({}),
  });

  const { watch, trigger, setError } = formHandlerObject;

  useEffect(() => {
    updateCurrentJourneyStepinCDP();
    prepopulateMyProfile();
    trackEvent({
      event: 'Participant Type identified',
      participantType: PatientType.ADULT,
    });
  }, []);

  useEffect(() => {
    const existingValues = formHandlerObject?.getValues();
    formHandlerObject.reset({ ...existingValues, ...getFormDataDefaultValue(existingValues) });
  }, [prepopulateConfig, sitecoreFieldsForContactInfo, myProfileData]);

  const populateExistingMyProfileConsents = async (consents: any) => {
    try {
      if (consents) {
        const formattedConsents = { ...consents };
        delete formattedConsents.combinedConsentAccepted;
        updateConsents('secondary', formattedConsents);
      }
    } catch (_e) {
      return;
    }
  };

  const populateMyProfileGender = async (genderValue: any) => {
    try {
      updateGender(genderValue);
    } catch (_e) {
      return;
    }
  };

  const populateConditionOfInterest = async (conditionsList: any) => {
    try {
      const response = constructConditionsOfInterest(conditionsList, formatedCOIList);
      dispatch(setSelectedCOIList(response));
    } catch (_e) {
      return;
    }
  };

  useEffect(() => {
    if (myProfileData?.basicDetails?.gender) {
      populateMyProfileGender(myProfileData?.basicDetails?.gender);
    }
    if (myProfileData?.conditions) {
      populateConditionOfInterest(myProfileData?.conditions);
    }
  }, [myProfileData]);

  useEffect(() => {
    if (myProfileData?.basicDetails?.secondaryConsent) {
      setTimeout(() => {
        const isSecondaryConsentsLoaded: HTMLElement = document.querySelector(
          `input[name="SecondaryConsentMedium"]`
        ) as HTMLElement;
        if (isSecondaryConsentsLoaded && !consentPrepopulatedforTAEPR.current) {
          consentPrepopulatedforTAEPR.current = true;
          populateExistingMyProfileConsents(myProfileData?.basicDetails?.secondaryConsent);
        }
      }, 1000);
    }
  }, [myProfileData, stepNumber]);

  useEffect(() => {
    const debouncedCb = debounce(async (value: any, { name }) => {
      if (name === 'Email') {
        const isValid = await trigger(name);
        if (isValid) {
          await populateExistingUserConsents(value['Email']);
        }
      }
    }, 3000);

    const subscription = watch((v, n) => {
      debouncedCb(v, n);
      if (ConsentsMedium.length > 0) {
        validateSMSandIsMobile(v, trigger, setError, ContactNumberField);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const updatePotentialParticipantId = async () => {
    try {
      const { data: response } = await post(
        `/Participant/UpdatePotentialParticipantId?potentialId=${potentialId}&potentialParticipantId=${getCookie(
          'bx_guest_ref'
        )}`
      );
      return response;
    } catch (_e) {}
  };

  const { mutate: updatePpId } = useMutation(
    'update-potential-participantId',
    updatePotentialParticipantId,
    {
      onSuccess: (_data) => {
        return _data;
      },
      onError: () => undefined,
    }
  );

  const updateUCPMJobRecords = async (isUserRegistered = false) => {
    try {
      //fetching CR siteSetting data
      const { data } = await axios.get(`/api/cr-context-data`);
      const siteSettings = data?.siteSettings;
      if ((siteSettings as any)?.enableUCPMIntegration?.value === false) {
        return;
      }
      return await updateUCPMJobRecordsForCR({
        otSponsorId: siteSettings?.otSponsorId?.value || '',
        parentSponsorMDMID: siteSettings?.parentSponsorMDMEntityId?.value || '',
        protocolMDMID: siteSettings?.parentSponsorMDMEntityId?.value || '',
        sponsor: siteSettings?.sponsorName?.value || '',
        iqviaSponsorId: siteSettings?.iqviaSponsorId?.value || '',
        dataOrigin: isUserRegistered ? DataOrigin.TA_CR_UPDATE : DataOrigin.TA_CR_REGISTRATION,
        UCPMStudyName: siteSettings?.UCPMStudyName?.value || '',
        baseUrl: siteSettings?.baseUrl?.value || '',
      });
    } catch (_e) {}
  };

  const successCallbackRetry = async (data: any) => {
    const extensionData = {
      ContactFormSubmit: true,
      tenantKey: tenantKey,
    };
    cdpService.event(CDP_EVENTS.CONTACT_FORM_SUBMIT, extensionData);
    const { getValues } = formHandlerObject;
    const email = getValues('Email' as never);
    cdpService.identityEvent(email);
    dispatch(updateContactInfo(data));

    const mailIdList = siteSettings?.sendMailingList?.value || '';
    const baseUrl = siteSettings?.baseUrl?.value || '';
    const cleanBaseUrl = removeTrailingSlash(baseUrl);
    const myProfileEndpoint = siteSettings?.myProfileItemUrl?.value?.href || '/manage-my-profile';
    const myProfileUrl = `${cleanBaseUrl}${myProfileEndpoint}`;
    const source = siteSettings?.source?.fields?.name?.value;
    const successCallbackData = data;
    try {
      //TODO: Journey status and enrollment id should be part of query params
      const formData = getValues();
      const payloadData = formatAdultFormData(
        formData,
        selectedLanguage,
        getCookie('bx_guest_ref'),
        potentialId,
        autoCompleteLocationInfo,
        tenantKey,
        getOrCreateEnrollmentId(),
        cdpState?.patientReferralStatus,
        selectedPlace
      );
      if (!isEmpty(selectedCOIList)) {
        const communityList = getCommunities(selectedCOIList);
        const communities = getStringValFromArrayForCDP(communityList, 'value', 'key');
        (payloadData as any)['communities'] = communities;
        (payloadData as any)['conditionOfInterest'] = getRespectiveCOIList(selectedCOIList, true);
      }
      //journeyStatus is not needed at this point
      const enrollmentId = getOrCreateEnrollmentId();
      const cdpPayload = {
        ...payloadData,
        journeyStatusRequest: {
          enrollmentId: enrollmentId,
          journeyStep: JOURNEY_STEP.CONTACT_INFO,
          journeyStatus: '',
        },
      };
      // update existing record in db
      if (updatePpidRef.current) {
        updatePpId();
      }
      if (siteSettings?.source?.fields?.name?.value === 'CR') {
        const authenticatedUserToken = sessionStorage.getItem('myProfileVerificationToken');
        const isUserRegistered = authenticatedUserToken && isAuthenticated ? true : false;
        updateUCPMJobRecords(isUserRegistered);
      }
      //post data to cdp on contact form submission, so cdp can take decision on next possible path
      try {
        await savedataToCdp(cdpPayload);
      } catch (_e) {
        throw new Error('CPD API failed');
      }

      if (source === SITE_SOURCE) {
        callDapApiSendEmailAllDetailsAdult(
          cdpPayload,
          baseUrl,
          myProfileUrl,
          mailIdList,
          sitecoreContext?.siteSettingsId as any,
          sitecoreContext?.language as any
        );
      }
      await fetchProfileDataSeparateThread();
      // await
      //Exp api call to get next possible path
      try {
        //TODO: Need to change experience api one done from backend side, currently using old exp api
        const data = await fetchNextSteps();

        if (journeyType === JOURNEYS.JOURNEY_02 && data?.isVirtual) {
          await cdpService.updateIsVirtualinCDP(
            getCookie('bx_guest_ref'),
            cdpState?.guestType || 'visitor', // TODO: Confirm if it is Visitor or customer
            data?.isHumanAPIEnabledForVirtual,
            data?.sitesData,
            potentialId,
            potentialParticipant_ID,
            successCallbackData.patientId
          );

          const constructedSitesData = constructVirtualSiteData(
            data,
            tenantKey,
            enrollmentId,
            potentialId,
            potentialParticipant_ID,
            successCallbackData
          );

          const response = await post(
            '/SiteSelection/AddOrUpdatePatientSiteDetails',
            constructedSitesData
          );
          if (!response) {
            throw new Error('Error in updating Virtual Sites Data');
          }
        }

        const getPostToLLPayload = (): LifeLinkRequest => {
          const siteSettingsId: any = sitecoreContext?.siteSettingsId;
          const language: any = sitecoreContext?.language || 'en';
          return {
            tenantKey: siteSettings?.tenantKey?.value || '',
            enrollmentId: getOrCreateEnrollmentId(),
            lifeLinkEnabled: '1',
            subjectHighRisk: screenerOutput.r || '',
            baseURL: getBaseURL(),
            queryParams: getQueryParamsAsString(),
            sitecode: selectedPlace?.siteInfo?.siteCode || '',
            protocol: selectedPlace?.siteInfo?.protocol || '',
            languageName: language,
            siteSettingsId: siteSettingsId || '',
            isLLBackgroundJobCall: false,
            PotentialId: potentialId,
          };
        };

        const goToNextStep = async () => {
          let appointmentStatus = data?.appointmentStatus;
          if (!appointmentStatus) {
            appointmentStatus = 'NotInitiated';
          }
          if (journeyType === JOURNEYS.JOURNEY_01 && selectedPlace?.siteInfo?.isLifeLinkEnabled) {
            if (!data?.isPostTOLL) {
              const patientId = successCallbackData.patientId || '';
              await updateLLDetailsTODB(
                getOrCreateEnrollmentId(),
                tenantKey,
                appointmentStatus,
                potentialId,
                patientId
              );
            }
          }
          if (data?.nextStepUrl) {
            //TODO: this line need to remove once exp api is fully functional
            setCookie('journeyStep', JOURNEY_STEP.CONTACT_INFO, {
              expires: getCookieExpiryTime(
                siteSettings?.resumeEnrollmentExpirationDays?.value ||
                  ENROLLMENT_EXPIRY_TIME_FALLBACK
              ),
              secure: true,
            });
            if (journeyType === JOURNEYS.JOURNEY_03) {
              trackEvent({
                event: 'Completed Contact Form',
              });
            }
            await router.replace(
              {
                pathname: data?.nextStepUrl,
                query: getQuery(query),
              },
              data?.nextStepUrl
            );
          }
        };
        dispatch(storeCDPData(data));
        if (data?.isPostTOLL) {
          const payload = getPostToLLPayload();
          try {
            const dapApiLLResponse = await postToLifeLink(payload);
            if (dapApiLLResponse.lifeLinkUrl) {
              sessionStorage.setItem('isLLRedirection', 'true');
              window.location.href = dapApiLLResponse.lifeLinkUrl;
            } else {
              if (data?.nextStepUrl) {
                await goToNextStep();
              }
            }
          } catch (e: any) {
            if (data?.nextStepUrl) {
              await goToNextStep();
            }
          }
        } else if (data?.nextStepUrl) {
          await goToNextStep();
        }
      } catch (_e) {
        //TODO: need to check if exp api fails what need to be done
        throw new Error('EXP API Failed');
      }
    } catch (_e) {
      throw _e;
    }
  };
  const successCallback = async ({ data }: any) => {
    setIsLoading(true);
    try {
      await successCallbackRetry(data);
    } catch (e: any) {
      console.log(`Success callback failed. with error ${e?.message}`);
    }
    setIsLoading(false);
  };

  const fetchProfileDataSeparateThread = async () => {
    setTimeout(async () => {
      const myProfileVerificationToken = sessionStorage.getItem('myProfileVerificationToken');

      if (myProfileVerificationToken) {
        try {
          const { data } = await fetchProfileDetailsForCR(myProfileVerificationToken);
          if (data) {
            dispatch(setProfileData(data));
          }
        } catch (_e) {
          throw new Error('Fetch profile details failed');
        }
      }
    }, 500);
  };

  const savedataToCdp = async (cdpPayload: any) => {
    while (retryCount.current < MAX_RETRY_COUNT) {
      try {
        retryCount.current += 1;
        const data = await savePatientDelegateDetailsToCDP(
          `/api/contacts/AddUpdatePatientDetails/${getCookie('bx_guest_ref')}?guestType=${
            cdpState?.guestType || 'customer'
          }`,
          cdpPayload,
          siteSettings?.source?.fields?.name?.value
        );
        if (data && siteSettings?.source?.fields?.name?.value === 'CR') {
          dispatch(setAuthentication(true));
        }
        isCurrentResume.current = true;
        break; // Exit the loop if the retry succeeds
      } catch (e: any) {
        console.log(
          `Retry attempt for savedataToCdp ${retryCount.current} failed. with error ${e?.message}`
        );
        if (retryCount.current >= MAX_RETRY_COUNT) {
          console.log('All retry attempts failed');
          throw new Error('savedataToCdp failed'); // Exit the loop if all retries fail
        }

        // Wait for 1 second before retrying
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
  };

  const fetchNextSteps = async () => {
    let retryAttempts = 0;
    const extensionData = {
      enrollmentId: getEnrollmentId() as any,
    };
    while (retryAttempts < MAX_EXPERIENCE_RETRY_COUNT) {
      try {
        retryAttempts += 1;
        const experienceId = process.env.NEXT_PUBLIC_CDP_EXPERIENCE_CONTACTINFO_ID;

        const data = await fetchNextPossiblePathAndData(
          experienceId,
          false,
          false,
          sitecoreContext?.site?.name
        );
        cdpService.event(CDP_EVENTS.CONTACTFORM_EXPERIENCE_SUCCESS, extensionData);
        return data; // Exit the loop if the retry succeeds
      } catch (e: any) {
        console.log(
          `Retry attempt for fetchNextSteps ${retryAttempts} failed. with error ${e?.message}`
        );
        cdpService.event(CDP_EVENTS.CONTACTFORM_EXPERIENCE_FAILURE, extensionData);
        if (retryAttempts >= MAX_EXPERIENCE_RETRY_COUNT) {
          console.log('All retry attempts failed');
          throw new Error('fetchNextSteps failed'); // Exit the loop if all retries fail
        }

        // Wait for 2 second before retrying
        await new Promise((resolve) => setTimeout(resolve, 2000));
      }
    }
    setIsLoading(false);
  };

  const failureCallback = () => {
    setIsLoading(false);
    const extensionData = {
      ContactFormSubmit: false,
      tenantKey: tenantKey,
      journeyType: journeyType as string,
    };
    cdpService.event(CDP_EVENTS.CONTACT_FORM_ERROR, extensionData);
  };

  const { mutate } = useStoreContactInfoData(successCallback, failureCallback);

  const saveDataToDBAndCdp = async (data: any) => {
    let token: string | null = null;
    if (isEnableGoogleRecaptcha || isRecapchaEnabled) {
      if (!executeRecaptcha) {
        return false;
      }
      token = await executeRecaptcha('adult_form');
    }
    const contactInfoData = formatAdultFormData(
      data,
      selectedLanguage,
      getCookie('bx_guest_ref'),
      potentialId,
      autoCompleteLocationInfo,
      tenantKey,
      getOrCreateEnrollmentId(),
      cdpState?.patientReferralStatus,
      selectedPlace
    );
    const payload = {
      ...contactInfoData,
      token,
      contactFormSettingsId: reCaptchItem?.id,
      siteSettingsId,
    };
    if (!isEmpty(selectedCOIList)) {
      const communityList = getCommunities(selectedCOIList);
      const communities = getStringValFromArray(communityList, 'value', 'key');

      (payload as any)['conditionOfInterest'] = getRespectiveCOIList(selectedCOIList);
      (payload as any)['communities'] = communities;
    }
    dispatch(updateContactInfo(payload));
    setIsLoading(true);
    mutate({ url: '/Patient/Add', payload });
    return;
  };

  const populateExistingUserConsents = async (email: string) => {
    try {
      const { data: res } = await checkReturningUser(
        false,
        tenantKey,
        PatientType.ADULT,
        email,
        returningUserTimeInterval,
        returningUserTimeValue,
        '',
        '',
        '',
        false,
        true,
        sitecoreContext?.site?.name
      );
      retuningUser.current = res;
      if (res?.secondaryconsent?.combinedConsentAccepted === 'True') {
        if (Object.keys(res?.secondaryconsent).length) {
          updateConsents('combined', res?.secondaryconsent);
        }
      } else {
        if (Object.keys(res?.primaryconsent).length) {
          updateConsents('primary', res?.primaryconsent);
        }
        if (Object.keys(res?.secondaryconsent).length) {
          updateConsents('secondary', res?.secondaryconsent);
        }
      }
    } catch (_e) {
      retuningUser.current = null;
      return;
    }
  };

  const performReturningUserCheckOnSubmit = async (
    data: any,
    isResumeEmailCheck: boolean,
    isReturnCheck: boolean,
    isJourney3: boolean
  ) => {
    try {
      const email = data['Email'];
      setIsLoading(true);
      const { data: res } = await checkReturningUser(
        true,
        tenantKey,
        PatientType.ADULT,
        email,
        returningUserTimeInterval,
        returningUserTimeValue,
        '',
        '',
        '',
        isResumeEmailCheck,
        isReturnCheck,
        sitecoreContext?.site?.name,
        isJourney3
      );
      setIsLoading(false);
      retuningUser.current = res;

      if (res?.showResumeEmailModal === true && !isCurrentResume.current) {
        setEmailSentModal();
        showEmailSentModal();
      } else if (res?.isReturningUser === false) {
        if (res?.potentialParticipantId) {
          setCookie('bx_guest_ref', res?.potentialParticipantId, {
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
            secure: true,
          });
          dispatch(addpotentialParticipantId(res?.potentialParticipantId));
          updatePpidRef.current = true;
        }
        if (res?.browserId) {
          const cookieName = 'bid_' + process.env.NEXT_PUBLIC_CDP_CLIENT_KEY;
          setCookie(cookieName, res?.browserId, {
            secure: true,
            expires: getCookieExpiryTime(
              siteSettings?.resumeEnrollmentExpirationDays?.value || ENROLLMENT_EXPIRY_TIME_FALLBACK
            ),
          });
        }
        await saveDataToDBAndCdp(data);
      } else {
        if (res?.showCustomErrorMessageModel) {
          // show modal popup
          setCustomErrorModal();
          showCustomErrorModal();
        } else if (res?.showPatientExistModel) {
          // show modal popup
          setPatientExistModal();
          showPatientExistErrorModal();
        }
        return;
      }
    } catch (_e) {
      setIsLoading(false);
      return;
    }
  };

  const onSubmit = async (data: any) => {
    retryCount.current = 0;
    let isResumeEmailCheck = false;
    let isReturnCheck = false;
    let isJourney3 = false;
    if (journeyType === JOURNEYS.JOURNEY_03) {
      isResumeEmailCheck = !siteSettings?.disableResumeJourney?.value ? true : false;
      isJourney3 = true;
    }
    if (journeyType === JOURNEYS.JOURNEY_02) {
      isResumeEmailCheck = !siteSettings?.disableResumeJourney?.value ? true : false;
      isReturnCheck = true;
    }
    if (journeyType === JOURNEYS.JOURNEY_01) {
      isResumeEmailCheck = false;
      isReturnCheck = true;
    }
    performReturningUserCheckOnSubmit(data, isResumeEmailCheck, isReturnCheck, isJourney3);
  };

  return (
    <ContactInfoContextProvider formHandlerObject={formHandlerObject} zipCode={null}>
      <ContactInfo onSubmit={onSubmit} fields={props?.fields} isLoading={isLoading} />
    </ContactInfoContextProvider>
  );
};

export const getStaticProps: GetStaticComponentProps = async (_rendering, layoutData) => {
  //TODO: Need to check if we can have this as commom code, can be used for other contact forms
  const context = layoutData?.sitecore?.context as ExtenedLayoutServiceContextInterface;
  const language = context?.language || process.env.DEFAULT_LANGUAGE || 'en';
  const countryId = context?.siteSettings?.country?.id as string;

  if (process.env.JSS_MODE === constants.JSS_MODE.DISCONNECTED) {
    return null;
  }
  const stateList = await getAllStates(language, countryId);
  const timeZoneList = await getAllTimeZones(language, countryId);
  const COIList = await fetchConditionOfInterest(language);

  return {
    stateList,
    timeZoneList,
    COIList,
  };
};
export default AdultContactInfo;
